/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    'tinySliderSetup': function () {

      //console.log('tinySliderSetup');

      $.each($('.tiny-slider'), function (index, value) {

        var thisContainer = '#' + $(this).attr('id');

        opts = {
          container: thisContainer,
          mode: 'gallery',
          controlsPosition: 'bottom',
          controlsText: [
            '<img src="/wp-content/themes/lupitaisland/dist/images/arrow-left.png"/>',
            '<img src="/wp-content/themes/lupitaisland/dist/images/arrow-right.png"/>'
          ],
          navPosition: 'bottom',
          autoplayButton: false,
          items: 1,
          slideBy: 'page',
          speed: 1500,
          animateIn: "fadeIn",
          animateOut: null
        };


        if (typeof $(this).data('autoplayTimeout') !== 'undefined' && $(this).data('autoplayTimeout') > 0) {
          opts.autoplay = true;
          opts.autoplayTimeout = $(this).data('autoplayTimeout') * 1000;
        }


        var newSlider = tns(opts);

      });


      $('.fake-controls .fake-previous').click(function () {
        $(this).parent().parent().find('.tns-controls button[data-controls="prev"]').click();
      });

      $('.fake-controls .fake-next').click(function () {
        $(this).parent().parent().find('.tns-controls button[data-controls="next"]').click();
      });


    },
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        //console.log('common.init');

        // Header fix
        $(window).on("scroll", function () {
          if ($(window).scrollTop() > 100) {
            $('header.banner').addClass('mini-me');
            $('header.banner .logo-con').addClass('mini-logo');
            $('#foot-book-btn').removeClass('d-none').addClass('d-block');
          } else {
            $('header.banner').removeClass('mini-me');
            $('header.banner .logo-con').removeClass('mini-logo');
            $('#foot-book-btn').removeClass('d-block').addClass('d-none');
          }
        });


        Sage.tinySliderSetup();


        // NAVIGATION

        $('#openNav').click(function () {
          //console.log('open');
          $("#myNav").addClass('active');
        });

        $('#closeNav').click(function () {
          $("#myNav").removeClass('active');
        });


      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //console.log('common.finalize');


      },
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    'recommendations': {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {


        // ISOTOPE FILTERING
        var $grid = $('.grid').isotope({
          itemSelector: '.element-item',
          layoutMode: 'vertical'
        });

        // bind filter button click
        $('#filters').on('click', 'button', function () {

          var filterValue = $(this).attr('data-filter');
          // use filterFn if matches value
          //filterValue = filterFns[ filterValue ] || filterValue;
          $grid.isotope({filter: filterValue});
        });

        // change is-checked class on buttons
        $('.button-group').each(function (i, buttonGroup) {
          var $buttonGroup = $(buttonGroup);
          $buttonGroup.on('click', 'button', function () {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $(this).addClass('is-checked');
          });
        });


      }
    },


    'rooms': {
      init: function () {
        // JavaScript to be fired on the about us page

        console.log('rooms./init');

        $('.element-item-room').click(function (e) {
          e.preventDefault();
          var slug = $(this).data('slug');

          $("#room-target").fadeOut().load(slug + " main#main > *", null, function () {

            console.log(History);
            console.log(slug);
            console.log(History.pushState(null, 'test', $(this).data('slug')));

            History.pushState(null, $(this).data('slug'), $(this).data('slug'));

            //Sage.common.init();
            //Sage.common.finalize();
            $(this).fadeIn();
            Sage.tinySliderSetup();

          });

        });

      },
      finalize: function () {


        var $container = $('.grid'),
          $body = $('body');

        $container.isotope({
          // disable window resizing
          layoutMode: 'fitRowsCentered',
          itemSelector: '.element-item',
        });


        // bind filter button click
        $('#filters').on('click', 'button', function () {

          var selector = $(this).attr('data-filter');

          $container.isotope({
            filter: selector
          });

          return false;
        });

        // change is-checked class on buttons
        $('.button-group').each(function (i, buttonGroup) {
          var $buttonGroup = $(buttonGroup);
          $buttonGroup.on('click', 'button', function () {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $(this).addClass('is-checked');
          });
        });

        $('.element-item-room[data-roomnumber="1"]').click();



      }
    },


      'food': {
        init: function () {
          //console.log('food./init');
          //$('.grid-menu .element-item').hide();
        },
        finalize: function () {
          //console.log('food./finalize');

          var $container = $('.grid'),
            $body = $('body');

          $container.isotope({
            // disable window resizing
            layoutMode: 'vertical',
            vertical: {
              horizontalAlignment: 0.5,
            },
            itemSelector: '.element-item',
            filter: '.breakfast'
          });

          $container.isotope( 'on', 'arrangeComplete', function() {
            //console.log('arrange is complete');
          });

          // bind filter button click
          $('#filters').on('click', 'button', function () {

            var selector = $(this).attr('data-filter');

            $container.isotope({
              filter: selector
            });

            return false;
          });

          // change is-checked class on buttons
          $('.button-group').each(function (i, buttonGroup) {
            var $buttonGroup = $(buttonGroup);
            $buttonGroup.on('click', 'button', function () {
              $buttonGroup.find('.is-checked').removeClass('is-checked');
              $(this).addClass('is-checked');
            });
          });

          $('.element-item[data-roomnumber="1"]').click();


        }

      }


    };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




(function (window, factory) {
    'use strict';
    // universal module definition
    if (typeof define === 'function' && define.amd) {
        // AMD
        define([
            'get-size/get-size',
            'isotope/js/layout-mode'
        ],
          factory);
    } else if (typeof module === 'object' && module.exports) {
        // CommonJS
        module.exports = factory(
          require('get-size'),
          require('isotope-layout/js/layout-mode')
        );
    } else {
        // browser global
        factory(
          window.getSize,
          window.Isotope.LayoutMode
        );
    }

}(window, function factory(getSize, LayoutMode) {
    'use strict';

    // -------------------------- definition -------------------------- //

    // create an Outlayer layout class
    var FitRowsCentered = LayoutMode.create('fitRowsCentered');
    var proto = FitRowsCentered.prototype;

    proto._resetLayout = function () {
        // pre-calculate offsets for centering each row
        this.x = 0;
        this.y = 0;
        this.maxY = 0;
        this._getMeasurement('gutter', 'outerWidth');
        this.centerX = [];
        this.currentRow = 0;
        this.initializing = true;

        //console.log(this.isotope);

        for (var i = 0, len = this.isotope.items.length; i < len; i++) {
            var item = this.isotope.items[i];
            this._getItemLayoutPosition(item);
        }

        //alert(this.isotope.filteredItems.length);

        this.centerX[this.currentRow].offset = (this.isotope.size.innerWidth + this.gutter - this.x) / 2;

        this.initializing = false;
        this.currentRow = 0;

        // centered offsets were calculated, reset layout
        this.x = 0;
        this.y = 0;
        this.maxY = 0;

        this._getMeasurement('gutter', 'outerWidth');
    };

    proto._getItemLayoutPosition = function (item) {
        item.getSize();
        var itemWidth = item.size.outerWidth + this.gutter;
        // if this element cannot fit in the current row
        var containerWidth = this.isotope.size.innerWidth + this.gutter;
        if (this.x !== 0 && itemWidth + this.x > containerWidth) {

            if (this.initializing) {
                this.centerX[this.currentRow].offset = (containerWidth - this.x) / 2;
            }
            this.currentRow++;

            this.x = 0;
            this.y = this.maxY;
        }

        if (this.initializing && this.x === 0) {
            this.centerX.push({ offset: 0 });
          //  alert("kokot");
        }

        var position;

        if (typeof this.centerX[this.currentRow] !== 'undefined') {
             position = {
                x: this.x + (this.initializing ? 0 : this.centerX[this.currentRow].offset),
                y: this.y
            };
        } else {
             position = {
                x: this.x,
                y: this.y
            };
        }



        this.maxY = Math.max(this.maxY, this.y + item.size.outerHeight);
        this.x += itemWidth;

        return position;
    };

    proto._getContainerSize = function () {
        return { height: this.maxY };
    };

    return FitRowsCentered;

}));
